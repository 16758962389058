import React, { useState, useEffect } from 'react';
import ZoomableAsset from './../ZoomableAsset';
import { useInView } from 'react-hook-inview'
import DOMPurify from 'dompurify';

import './Highlights.scss';

const InViewInnerComponent = ({children}) => {
    const [ref, isVisible] = useInView({
        threshold: 0.3,
    });
    return (<div className={`inview-inner-${isVisible}`} ref={ref}>{children}</div>)
}

const HighlightItems = ({ title, highlightList }) => {
    return(
        <div className="hightlights_inners hightlights_list"><h3 className="hightlights_title">{title}</h3>
            {highlightList.map( item => {
                return(
                    <div key={item.contentID} className={`hightlights_items ${item.fields.layout}`}>
                        <div className="hightlights_item_title">{item.fields.title}</div>
                        <div className="hightlights_item_desc" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(`<span>${item.fields.description.replace(/\n/g, '</span><span>')}</span>`)}} />
                    </div>
                )
            })}
        </div>
    );
}

const Highlights = ({agility, content, image, title, highlights, featuredContent, modalImage, setModalImage, setZoom }) => {
    const [highlightList, sethighlightList] = useState(false);

    useEffect( () => {
        async function fetchhighlightData() {
            if (window._riverbend)   {
                const _socialIconList = await agility.client.getContentList(highlights.referencename);
                sethighlightList(_socialIconList.items);
            }
            else {
                sethighlightList(highlights);
            }
        }
        fetchhighlightData();
    }, []);

    return(
        <section className="hightlights">
            <InViewInnerComponent>
            <div className="hightlights_top">
            <div className="hightlights_top_inner">
                <div className="hightlights_inners hightlights_content" dangerouslySetInnerHTML={{__html: !!content ? content : ''}} ></div>
                <div className="hightlights_inners hightlights_img">
                    <ZoomableAsset modalImage={modalImage} setModalImage={setModalImage} setZoom={setZoom} {...image} />
                </div>
            </div>
            </div>
            </InViewInnerComponent>
            <InViewInnerComponent>
            <div className="hightlights_bottom">
            <div className="hightlights_bottom_inner">
                {highlightList && <HighlightItems title={title} highlightList={highlightList}/>}                
                <div className="hightlights_inners hightlights_features"  dangerouslySetInnerHTML={{__html: !!featuredContent ? featuredContent : ''}} ></div>
            </div>
            </div>
            </InViewInnerComponent>
        </section>
        
        
    );
}

export default Highlights;
